
const ServiceData = [

        {
          id:"as1",
          icon: "fas fa-bell",
          iconColor: "#fd346e",
          title: "Excision of Lingual thyroid - How I do it.",
          text:
            "Journal of International Medical Sciences Academy (April-June 2015)",
          
        },
        {
          id:"as2",
          icon: "fas fa-chart-pie",
          iconColor:"#fcdc00",
          title: "Chandrasekaran's approach for thyromegaly",
          text:
            " Published in Indian Journal of Endocrine Surgery (April 2003*)",
        },
        {
          id:"as3",
          icon: "fas fa-headphones",
          iconColor:"#ff5722",
          title: "Effect of Compulsory Iodisation of Salt in South India",
          text:
            "Indian Journal of Endocrinology and Metabolism (March-April 2013)",
        },
        {
          id:"as4",
          icon: "fas fa-envelope",
          iconColor:"#ff5722",
          title: "Lipid peroxidation and antioxidants status in thyroid tumours",
          text:
            "SAGE journals for Human & Experimental Toxicology (April 2015)",
        },
        {
          id:"as5",
          icon: "fas fa-layer-group",
          iconColor:"#6001d3",
          title: "Viability of Grafts after Parathyroid Autotransplantation",
          text:
            "Journal of International Medical Sciences Academy (April-June 2015)",
        },
  
      ]
  export default ServiceData;