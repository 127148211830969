import React from 'react'
import TestiomialCard from './Components/Card'
import SectionTitle from './Components/SectionTitle'
import {Images} from "~data"
import Testimonial from './style'
import { Col, Container, Row } from 'react-bootstrap'
export default function TestimonialSection(){
return(
<Testimonial backgroundColor="#f3f4f6">
  <Container>
    <Row className="justify-content-center">
      <Col className="col-lg-9 col-xl-9 col-xxl-7 text-center">
        <SectionTitle
        subTitle="Patients Speak" 
        title="Our patients share their experiences"
        subTitleProps={{mb:"27px"}}
        titleProps={{mb:"10px",as:"h2"}}
        mb="50px" />
      </Col>
    </Row>
    <Row className="justify-content-center">
        <Col className="col-xl-4 col-md-6">
            <TestiomialCard image={Images.agency.testiMonialUserimg1} text="Dr. Chandrashekaran is very senior and has experience of more than 7000 surgeries in his 37 years of profession... he is very caring and personally attends his patients at least two times in a day..." userName="Shekhar Desarda" userPosition="Google Review"/>
        </Col>
        <Col className="col-xl-4 col-md-6">
            <TestiomialCard image={Images.agency.testiMonialUserimg1} text="My mother underwent Thyroid gland removal surgery and was near normal in a day.Excellent care by Dr Chandrasekhar and his dedicated team.Thanks for the care and efforts by the team!!!👍👍👍" userName="Indraraj Inder" userPosition="Google Review"/>
        </Col>
        <Col className="col-xl-4 col-md-6">
            <TestiomialCard image={Images.agency.testiMonialUserimg1} text="'Fortunate' is the word that i would describe about my experience with Prof. Dr. Chandrasekharan & his team of doctors who ensured i get the best treatment during my Total Thyroidectomy surgery," userName="Badrinath Ramasubramanian" userPosition="Google Review"/>
        </Col>
    </Row>
  </Container>
</Testimonial>
)
}