import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Video from '~components/VideoModal'
import { Link } from '~components';
import { Images } from '~data'
 import Hero from "./style"
export default function HeroSection(){
return(
<Hero backgroundImage={Images.agency.heroImg} backgroundOverlay={Images.agency.heroImgOverlay}>
  <Container>
    <Row className="align-items-center justify-content-center justify-content-md-start">
      {/* Welcome content Area */}
      <Col className="col-xxl-6 col-xl-7 col-lg-8 col-md-8 col-xs-11 order-2 order-lg-1">
        <Hero.Content mb="40px" mbMD="40px" mt="30px" mtLG="0">
        <Hero.Subtitle as="h3" fontColor="#fee856" mb="28px">Exclusive Thyroid Clinic</Hero.Subtitle>
        <Hero.Title as="h1" fontColor="#fff">All in Thyroid & <br/> 
        Only Thyroid.</Hero.Title>
        
          <Hero.Text>Take care of all your thyroid needs under one roof. <br className="d-none d-sm-block" />
          Book an consultation session with <br></br>Dr. M. Chandrasekaran today.</Hero.Text>
          <Hero.Button className="btn-primary btn-1" as={Link} to="contact-us">Book Appointment</Hero.Button>
          {/* <Hero.Video mt="40px">
            <Video id="LWZ7iytIA6k" className="video-block">
              <i className="fa fa-play" />
              <span className="btn-text">Book Appointment</span>
            </Video>
          </Hero.Video> */}
        </Hero.Content>
      </Col>
      {/*/ .Welcome Content Area */}
    </Row>
  </Container>
</Hero>
)
}