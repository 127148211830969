import React from 'react'
import { StaticImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from '~components';
import About from './style'
import SectionTitle from './Components/SectionTitle'
import Video from '~components/VideoModal'
export default function AboutSection(){
return(
<About>
  <Container>
    {/* about-us Content */}
    <Row className="align-items-center justify-content-center">
      <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
        <About.Image>
          <Img className="w-100" src="../../../assets/image/home-agency/about-us-l5-img.png" alt="about" layout="fullWidth" placeholder="blurred"/>
          <Video id="Rz0ilaGYUpw" className="video-btn sonar-emitter"> <i className="fa fa-play" /> </Video>
        </About.Image>
      </Col>
      <Col col="12" className="col-xxl-5 offset-xxl-1 col-lg-5 col-md-8 col-xs-10">
        <About.Content mt="40px" mtLG="0" mb="50px" mbLG="0" mlLG="30px">
              <SectionTitle 
                subTitle="Watch video" 
                title="An Interview with Doordarshan"
                text="In 2011, Doordarshan did an interview with Dr.M.Chandrasekaran who was then the HoD of the Department of Endocrine Surgery at Madras Medical College. "
                titleProps={{mb:"30px"}}
                subTitleProps={{mb:"25px"}}
                />

        </About.Content>
      </Col>
    </Row>
    {/*/ .about-us Content */}
  </Container>
</About>

)
}
